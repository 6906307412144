<template>
  <Layout>
    <PageHeader :title="title" :items="items"/>
    <div class="row">
      <div class="card">
        <div class="card-body">
          <h4 class="card-title mb-4">Liste des personnes recherchées</h4>
          <div class="row justify-content-between">
            <div class="col-md-5">
              <div class="form-group mt-3 mb-0">
                <div class="search-box me-2">
                  <div class="position-relative">
                    <input type="text" @input="searchFilter($event)" class="form-control bg-soft bg-info border-0"
                           placeholder="Rechercher..."/>
                    <i class="bx bx-search-alt search-icon"></i>
                  </div>
                </div>
              </div>
            </div>
            <div class="row col-md-6">
              <div class="form-group col-md-4 mt-3 mb-0">
                <datepicker v-model="picked" :first-day-of-week="1" lang="en" confirm class="form-control"
                            placeholder="Date de début"></datepicker>
              </div>

              <div class="form-group col-md-4 mt-3 mb-0">
                <datepicker class="form-control" placeholder="Date de fin" v-model="daterange" range
                            append-to-body lang="en" confirm></datepicker>
              </div>

              <div class="mt-3 col-md-4 mb-0">
                <button class="btn btn-info">
                  <i class="bx bx-slider align-middle me-1"></i> Lancer le filtre
                </button>
              </div>
            </div>
          </div>
          <div class="table-responsive mt-3" v-if="!loading">
              <div class="row">
                <div class="col-lg-3 col-md-6 col-sm-12" v-for="personne in personneData" :key=personne.id>
                  <a @click="detailsPerson(personne)" style="text-decoration: none; color: inherit;">
                    <div class="card mb-4 text-center">
                      <div class="col-sm-12">
                        <div class="d-flex justify-content-center align-items-center" style="height: 150px; overflow: hidden;">
                          <img v-if="personne?.photos?.length > 0" class="d-block img-fixed-size" style="max-width: 100%; max-height: 100%;" :src="`https://api.alcit.sims-technologie.com${personne?.photos[0]?.photos}`" alt="">
                          <img v-else class="d-block img-fixed-size" style="max-width: 100%; max-height: 100%;" src="https://static.thenounproject.com/png/363640-200.png" alt="">
                        </div>
                      </div>
                      <div class="card-body">
                        <h5 class="card-title text-info">{{ personne.nom }} <br> {{ personne.prenom }}</h5>
                        <p class="card-text"><strong>Sexe: </strong>{{ personne.sexe }} <br/> <strong>Naissance:</strong>  {{ personne.date_naissance }} </p>
                        <p class="card-text"><strong>Motif: </strong>{{ personne.motif_recherche }}</p>
                      </div>
                    </div>
                  </a>
                </div>
              </div>

          </div>


<!--          <div class="table-responsive mt-3"  v-if="!loading">
            <div class="row">
              <div class="col-lg-3 col-md-4 col-sm-6" v-for="personne in personneData" :key=personne.id>
                <div class="card float-right" @click="detailsPerson(personne)">
                  <div class="row">
                    <div class="col-sm-5">
                      <img v-if="personne?.photos?.length > 0" class="d-block img-fixed-size" width="100" height="100" :src="`https://api.alcit.sims-technologie.com${personne?.photos[0]?.photos}`" alt="">
                      <img v-else class="d-block img-fixed-size" width="100" height="100" src="https://static.thenounproject.com/png/363640-200.png" alt="">
                    </div>
                    <div class="col-sm-7">
                      <div class="card-block">
                        &lt;!&ndash;           <h4 class="card-title">Small card</h4> &ndash;&gt;
                        <strong>{{ personne.nom }} {{ personne.prenom }}</strong>
                        <p><strong>Sexe: </strong> {{ personne.sexe }}, <br/> <strong>Age: </strong> </p>
                        <p><strong>Status: </strong> {{ personne.status }} </p>
                      </div>
                    </div>
                    <div class="col-sm-12">
                      <p><strong>Motif: </strong> {{ personne.motif_recherche }}</p>
                    </div>

                  </div>
                </div>
              </div>
            </div>


            &lt;!&ndash;                        <table class="table table-nowrap table-centered mb-0 align-middle">
                                        <thead class="table-light">
                                            <tr>
                                                <th>Nom et Prenom</th>
                                                <th scope="col">Motif</th>
                                                <th scope="col">Niveau dangerosité</th>
                                                <th>Conduite à tenir</th>
                                                &lt;!&ndash; <th scope="col">Date</th> &ndash;&gt;
                                                <th scope="col">Statut</th>
                                                <th>Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="personne in personneData" :key=personne.id>
                                                <td>{{ personne.nom }} {{ personne.prenom }}</td>
                                                <td>{{ personne.motif_recherche }}</td>
                                                <td>{{ personne.niveau_dangerosite }}</td>
                                                <td>{{ personne.conduite_a_tenir }}</td>
                                                &lt;!&ndash; <td>{{ personne.date }}</td> &ndash;&gt;

                                                <td>
                                                    {{ personne.status }}
                                                    &lt;!&ndash; <span class="badge font-size-11 m-1" :class="{
                                                        'bg-success': `${personne.status}` === 'retrouvé',
                                                        'bg-danger': `${personne.status}` === 'Non retrouvé',
                                                    }">{{ personne.status }}</span> &ndash;&gt;
                                                </td>
                                                <td>
                                                    <button class="btn btn-warning btn-sm me-2 w-xs" @click="detailsPerson(personne)">Informations</button>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>&ndash;&gt;


          </div>-->
          <div v-else class="loading-container">
            <div class="text-center">
              <i class="bx bx-loader bx-spin" style="font-size: 5em;"></i>
              <h1>Chargement</h1>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>



<script>
import Layout from "../../layouts/main";
import Datepicker from "vue3-datepicker";
import PageHeader from "@/components/page-header";
import {reqMethods} from '../../../state/helpers';


/**
 * Lending component
 */
export default {
  components: {
    Layout, PageHeader,
    Datepicker
  },
  setup() {

  },
  data() {
    return {
      picked: "",
      daterange: "",
      fetchError: null,
      data: {},
      loading: false,
      personneData: {},
      allPersonnes: {},
      title: "Liste de personne recherchées",
      items: [
        {
          text: "Dashboard",
          href: "/"
        },
        {
          text: "Personnes recherchées",
          active: true
        }
      ]
    };
  },

  methods: {
    ...reqMethods,
    detailsPerson(personne) {
      localStorage.setItem('person', JSON.stringify(personne))
      this.$router.push({path: `/DelailPerson/${personne.id}`})
    },
    getAllPersonnes() {
      const that = this;
      this.loading = true
      this.getRequest('/personne/personne/')
          .then(
              function (response) {
                that.personneData = response.data;
                that.allPersonnes = response.data;
              },
              function (error) {
                that.fetchError = error;
              }
          ).finally(
          () => {
            that.loading = false;
          }
      )
    },
    searchFilter(e) {
      const searchStr = e.target.value;
      this.personneData = this.allPersonnes.filter((personne) => {
        return (
            personne.nom.toLowerCase().includes(searchStr.toLowerCase()) ||
            personne.prenom.toLowerCase().includes(searchStr.toLowerCase()) ||
            personne.motif_recherche.toLowerCase().includes(searchStr.toLowerCase()) ||
            personne.conduite_a_tenir.toLowerCase().includes(searchStr.toLowerCase())
        );
      });
    },
  },
  mounted() {
    this.getAllPersonnes();

  },

};
</script>

<style scoped>
.img-fixed-size {
  width: 150px;
  height: 150px;
  object-fit: cover;
}
.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.title {
  margin-bottom: 50px;
  text-transform: uppercase;
}

.card-block {
  font-size: 1em;
  position: relative;
  margin: 0;
  padding: 1em;
  border: none;
  border-top: 1px solid rgba(34, 36, 38, .1);
  box-shadow: none;
}

.card {
  font-size: 1em;
  overflow: hidden;
  padding: 5px;
  border: none;
  border-radius: .28571429rem;
  box-shadow: 0 1px 3px 0 #d4d4d5, 0 0 0 1px #d4d4d5;
  margin-top: 20px;
}

.carousel-indicators li {
  border-radius: 12px;
  width: 12px;
  height: 12px;
  background-color: #404040;
}

.carousel-indicators li {
  border-radius: 12px;
  width: 12px;
  height: 12px;
  background-color: #404040;
}

.carousel-indicators .active {
  background-color: white;
  max-width: 12px;
  margin: 0 3px;
  height: 12px;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E") !important;
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E") !important;
}

.btn {
  margin-top: auto;
}
</style>

